import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import Container from 'components/Container'

const Description = styled.p`
  margin-bottom: 10px;
  display: inline-block;
`

export default function Index({ data: { site, allMdx } }) {

  return (
    <Layout site={site}>
      <Hero
        heading="Contact"
        subHeading="Drop me a message to chat about development things"
        blog={false}
        avatar={true}
      />
      <Container>
        <Description>
          alan@usedfuture.digital
        </Description>

      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { ne: false } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 190)
          id
          fields {
            title
            slug
            date
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            banner {
              childImageSharp {
                sizes(maxWidth: 720) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            slug
            keywords
          }
        }
      }
    }
  }
`
